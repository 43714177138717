<template>
  <Disclosure as="div" :defaultOpen="defaultOpen" v-slot="{ open }">
    <DisclosureButton
      as="div"
      class="flex items-center rounded-md py-3 pr-2 pl-4 text-lg leading-6 font-normal text-gray-700 hover:bg-gray-50 hover:pl-5 transition-all duration-300 ease-in-out"
    >
      <FontAwesomeIcon
        v-if="icon"
        :icon="icon"
        class="text-2xl shrink-0"
        aria-hidden="true"
      />
      <FontAwesomeIcon
        v-else
        :icon="faCircle"
        class="text-2xl shrink-0"
        aria-hidden="true"
      />
      <span class="grow select-none pl-4">
        {{ t(name, 3) }}
      </span>
      <FontAwesomeIcon
        :icon="faChevronRight"
        :class="[
          open ? 'rotate-90 text-gray-500' : 'text-gray-400',
          'h-5 w-5 shrink-0',
        ]"
        aria-hidden="true"
      />
    </DisclosureButton>
    <DisclosurePanel as="ul" class="mt-1 px-2 space-y-1 border-l-2">
      <slot></slot>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronRight, faCircle } from '@fortawesome/pro-regular-svg-icons'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'

const { t } = useI18n()

const props = defineProps<{
  name: string
  icon?: IconDefinition
  defaultOpen?: boolean
}>()
</script>
